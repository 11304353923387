import React from 'react';
import './AboutStages.scss';
import AboutStagesItem from './AboutStagesItem';
import Ad from '../../../../assets/images/Ad.png';
import Alasan from '../../../../assets/images/Alasan.png';
import Brahim from '../../../../assets/images/Brahim.png';
import Guey from '../../../../assets/images/Guey.png';
import Bechir from '../../../../assets/images/Bechir.png';
import Nazirou from '../../../../assets/images/Nazirou.png';
import { useTranslation } from 'react-i18next';
const AboutStages2 = () => {
  const { t } = useTranslation();
  const stages = [
    {
      h2: t('StagesH2'),
    },
    {
      text: t('Expert1'),
      image: Ad,
    },
    {
      text: t('Expert2'),
      image: Alasan,
    },
    {
      text: t('Expert3'),
      image: Brahim,
    },
    {
      text: t('Expert4'),
      image: Guey,
    },
    {
      text: t('Expert5'),
      image: Bechir,
    },
    {
      text: t('Expert6'),
      image: Nazirou,
    },
  ];

  return (
    <div className='about-stages container p-5'>
      <div className='row'>
        {stages.map((e) => (
          <AboutStagesItem
            text={e?.text}
            y={e?.y}
            image={e?.image}
            h2={e?.h2}
          />
        ))}
      </div>
    </div>
  );
};

export default AboutStages2;
