import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Ad from '../../../../assets/images/Ad.png';
import Alasan from '../../../../assets/images/Alasan.png';
import Brahim from '../../../../assets/images/Brahim.png';
import Guey from '../../../../assets/images/Guey.png';
import Bechir from '../../../../assets/images/Bechir.png';
import Nazirou from '../../../../assets/images/Nazirou.png';
import { useTranslation } from "react-i18next";

const AboutStagesMobile2 = () => {
  const { t } = useTranslation();
  return (
    <div className="about-stages-mobile container">
      <h2 data-aos="zoom-in">{t("StagesH2")}</h2>
      <Swiper
        modules={[Navigation, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
      >
        <SwiperSlide>
          <div className="stages-item p-5">
            <img data-aos="fade-left" src={Ad} alt="tree" />
            <p data-aos="fade-left">{t("Expert1")}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="stages-item p-5">
            <img data-aos="fade-left" src={Alasan} alt="Alasan" />
            <p data-aos="fade-left">{t("Expert2")}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="stages-item p-5">
            <img data-aos="fade-left" src={Brahim} alt="Brahim" />
            <p data-aos="fade-left">{t("Expert3")}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="stages-item p-5">
            <img data-aos="fade-left" src={Guey} alt="Guey" />
            <p data-aos="fade-left">{t("Expert4")}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="stages-item p-5">
            <img data-aos="fade-left" src={Bechir} alt="Bechir" />
            <p data-aos="fade-left">{t("Expert5")}</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="stages-item p-5">
            <img data-aos="fade-left" src={Nazirou} alt="Nazirou" />
            <p data-aos="fade-left">{t("Expert6")}</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default AboutStagesMobile2;
