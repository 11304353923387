import React from 'react';

const Location = () => {
  return (
    <div data-aos='zoom-in' className='location'>
      <iframe
        title='map'
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4326.342580465117!2d-15.960819224281153!3d18.07350458293555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xe96526555555555%3A0xebcc13473402c03e!2sFST%2CUSTM!5e1!3m2!1sen!2sca!4v1723575716892!5m2!1sen!2sca'
        style={{ width: '100%', height: '300px', border: 0 }}
        allowFullScreen=''
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
      ></iframe>
    </div>
  );
};

export default Location;
