import React from 'react';
import AboutStages2 from './Components/AboutStages2/AboutStages2';
import AboutStagesMobile2 from './Components/AboutStages2/AboutStagesMobile2';
import AboutInfo from './Components/AboutInfo/AboutInfo';
import AboutInfo2 from './Components/AboutInfo/AboutInfo2';
import AboutLine from './Components/AboutLine/AboutLine';

const About = ({ about }) => {
  return (
    <div ref={about} className='about'>
      <AboutInfo />
      <AboutStages2 />
      <AboutStagesMobile2 />
      <AboutInfo2 />
      <AboutLine />
    </div>
  );
};

export default About;
