import React from 'react';
import './Footer.scss';
import { FiMail, FiPhone } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const today = new Date();
  return (
    <footer>
      <div className='container'>
        <div className='footer-content'>
          <div className='footer-info pb-2 pb-md-0 flex-column'>
            <p className='footer-item'>{t('Seller')}</p>
            <span className='footer-item'>
              {t('Copyright').replace('2023', today.getFullYear())}{' '}
              <a
                href='https://www.bsimr.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                www.bsimr.com
              </a>
            </span>
          </div>
          <div className='footer-info flex-column'>
            <p className='footer-item'>
              <FiPhone />+ 222 20 00 20 04
            </p>
            <p className='footer-item'>
              <FiPhone /> WhatsApp: + 222 20 28 00 00
            </p>
            <p className='footer-item'>
              <FiMail />
              <a
                href='mailto:contact@geoexplorersrv.com'
                title='contact@geoexplorersrv.com'
                aria-label='contact@geoexplorersrv.com'
              >
                contact@geoexplorersrv.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
